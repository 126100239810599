<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Daan de bruijn</h2>
          <p>
            Door mijn passie voor bewegen en het helpen van mensen, was het voor
            mij al vroeg duidelijk dat ik osteopaat wilde worden. Ik koos er
            voor om eerst fysiotherapie aan de Hogeschool Rotterdam te studeren
            om vervolgens direct daarna de opleiding tot osteopaat aan de IAO
            (International Academy of Osteopathie) te volgen.
          </p>
          <p>
            Tijdens deze vijfjarige opleiding heb ik gewerkt als fysiotherapeut
            in verschillende settingen (kliniek, eerste lijn en topsport) en heb
            ik regelmatig gastlessen mogen verzorgen in binnen- en buitenland.
          </p>
          <p>
            'Beweging', in de meest ruime zin van het woord, staat voor mij als
            osteopaat centraal. Een mens dient goed te kunnen bewegen om
            optimaal te kunnen functioneren. Indien dit niet meer lukt, ontstaan
            klachten. Een osteopaat spoort bewegingsverlies op van de
            verschillende lichaamssystemen en optimaliseert deze middels manuele
            technieken. Mijn toekomstideaal is om osteopathie op een positieve
            manier bekend te maken bij het grote publiek. Hierin streef ik een
            best evidice based practice na. Ik hecht dus veel waarde aan de
            wetenschappelijke onderbouwing van mijn behandelingen en het vak
            osteopathie in zijn geheel. In de praktijk bied ik extra
            ondersteuning bij het digitale aspect van de praktijkvoering.
          </p>
          <p>
            In mijn vrije tijd speelt bewegen ook een belangrijke rol. Ik sport
            graag. Op dit moment beoefen ik crossfit en ga regelmatig een rondje
            hardlopen. In het verleden heb ik fanatiek aan judo, schaatsen en
            wielrennen gedaan.
          </p>
          <h3>Specialismen</h3>
          <p>
            Ik behandel veel mensen met pijnklanten zoals lage rugklachten,
            nekpijn, hoofdpijn en migraine en stress gerelateerde klachten.
          </p>
          <p>
            Door mijn voorliefde voor sport en bewegen en mijn ervaring als
            fysiotherapeut in de topsport, behandel ik veel sporters met een
            verscheidenheid aan blessures (posttraumatische klachten). In het
            behandelplan neem ik ook preventie van blessures mee.
          </p>
          <p>
            Door mijn werkervaring als fysiotherapeut in het ziekenhuis, ben ik
            goed in de behandeling van mensen in postoperatieve trajecten.
          </p>
          <h3>Opleiding en Nascholing</h3>
          <ul>
            <li>
              Bacheloropleiding tot Fysiotherapeut aan de Hogeschool van
              Rotterdam
            </li>
            <li>
              Minor: ‘Hoofd’-zaken en capitale beslissingen aan de Hogeschool
              van Rotterdam
            </li>
            <li>The Internation Academy of Osteopathy in Zeist</li>
            <li>
              Cursus dry needling extremiteiten, Uplands Physiotherapy Clinic
            </li>
            <li>Symptomatisch perifeer arterieel vaatlijden</li>
            <li>Masterclass 11 hidden mechanisms for hamstring injuries</li>
            <li>Curses JOIN(T)Forces, preventie van knieblessures</li>
            <li>Live Surgery Heup-knie, Live Surgery Enkel-voet</li>
            <li>Evolutionary Medicine within the Osteopathic Field</li>
            <li>
              Sports Osteopathy aan Bucks New University en The International
              Academy of Osteopathy (start september 2020)
            </li>
          </ul>

          <img src="../../assets/team/daan.png" alt="Daan" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "Daan",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
